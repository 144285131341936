import React from "react"
import { Helmet } from "react-helmet"
import { Layout } from "../../components/layout"
import { Formation } from "../../data/formations/type"
import { FormationDetail } from "../../components/formations/FormationDetail"
import { FormationMeta } from "../../components/formations/FormationMeta"
import { FormationSessions } from "../../components/formations/FormationSessions"
import { shopActionsFromFormation } from "../../data/types/formations"

interface Props {
  pathContext: {
    formation: Formation
  }
}

const FormationTemplate: React.FC<Props> = ({ pathContext }) => (
  <Layout>
    <Helmet>
      <title>{`${pathContext.formation.titre} - ${pathContext.formation.reference} - Formations`}</title>
      <meta name="keywords" content={`arc, grand est, ${pathContext.formation.tags.join()}`} />
    </Helmet>
    <div className="container mt-3 formation">
      <h1>{pathContext.formation.titre}</h1>

      <div className="row">
        <div className="col-md-4 col-lg-3">
          <FormationMeta formation={pathContext.formation} />
        </div>
        <div className="col-md-8 col-lg-9">
          <FormationDetail formation={pathContext.formation} />
          <FormationSessions formation={pathContext.formation} shopActions={shopActionsFromFormation(pathContext.formation)} />
        </div>
      </div>
    </div>
  </Layout>
)

export default FormationTemplate
